var ctaObserver = function ctaObserver() {
  var elem = document.querySelector('.js-cta');
  var target = document.querySelector('.js-ctaTarget');
  var CLASS = 'is-disable';
  var options = {
    rootMargin: '10%',
    threthold: [0]
  };
  var callback = function callback(entries) {
    if (entries[0].isIntersecting) {
      elem.classList.add(CLASS);
    } else {
      elem.classList.remove(CLASS);
    }
  };
  var observer = new IntersectionObserver(callback, options);
  observer.observe(target);
};
export default ctaObserver;