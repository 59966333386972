"use strict";

import ctaObserver from "./modules/ctaObserver";

/* -----------------------------------
  MODULES
------------------------------------ */

window.addEventListener('DOMContentLoaded', function () {
  ctaObserver();
});